import React from 'react'
import './styles.css'
import { Container, Spinner } from 'react-bootstrap'
import YouTubeEmbed from '../youtubeEmbed'
import Carousel from 'react-bootstrap/Carousel';
import Title from '../title';

const Highlights = ({ highlights, loading }) => {
    return (
            <div className='highlightsWrapper'>
                {loading ?
                    <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spinner animation="grow" style={{ backgroundColor: "#000" }} />
                    </div>
                    :
                    <>
                        <div style={{paddingLeft:16}}>
                            <Title title="Football Highlights" />
                        </div>
                        <Carousel indicators={false}>
                            {
                                highlights?.map((highlight) => {
                                    return (
                                        <Carousel.Item style={{height:'70vh' , background:"red"}}>
                                            <YouTubeEmbed youtubeUrl={highlight?.strVideo} />
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </>
                }
            </div>
    )
}

export default Highlights