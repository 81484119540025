import React, { useRef, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import { Swiper, SwiperSlide } from "swiper/react";
import moment from 'moment';
import { Autoplay } from 'swiper/modules';
import Title from '../title';


export default function News({ news, loading }) {
    return (
        <>
            <Container className='NewsWrapper'>
                {
                    loading ?
                        <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner animation="grow" style={{ backgroundColor: "#000" }} />
                        </div>
                        :
                        <div className='newsContainer'>
                            <Title title="Latest News" />
                            {
                                news?.map((news, index) =>{
                                    return(
                                        <div key={index} className='newsContentContainer'>
                                            <img  src={news?.enclosures[0].url} alt='news image' width='100%' height={200}/>
                                            <div style={{padding:16}}>
                                                <div style={{display:'flex' , alignItems:'center' , justifyContent:"space-between"}}>
                                                   <p className='newsCategory'>{news.category}</p> 
                                                   <p className='newsPublished'>{moment(news.published).format('YYYY/MM/DD')}</p>
                                                </div>
                                                <h6>
                                                    {news.title}
                                                </h6>
                                                <div className='readMore' >
                                                    <a href={news?.link} className='readMoreLink' target='_blank'>
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </Container>
        </>
    )
}
