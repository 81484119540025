import React, { useEffect, useState } from 'react'
import './index.css'
import Cookies from 'universal-cookie';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { editUser, getUserById } from '../../../../api';
import profile from '../../../../assets/images/profile.jpg'
import Title from '../../../title';


const Profile = () => {
    const cookie = new Cookies()
    const [user, setUser] = useState([])
    const [pass, setPass] = useState('');

    useEffect(() => {
        const user = getUserById(cookie.get('id'))
        user.then(response => {
            setUser(response.data)
            setPass(response.data.password)
        })
    }, [])

    const editPassword = () => {
        if (user) {
            const data = {
                password: pass,
            }

            const updateUser = editUser(user._id, data)
            updateUser.then(response => {
                if (response.success) {
                    window.location.reload()
                } else {
                    alert(response.message)
                }
            })
        }

    }


    return (
        <Container style={{display:'flex', alignItems:'center', justifyContent:'center' , height:'100%'}}>
            <Row style={{width:"100%"}}>
               <div>
                <Title title='Profile' /> 
               </div>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='profileFieldsWrapper'>

                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicFirstName">
                                    <Form.Label className='inputLabel'>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter First Name"
                                        // onBlur={formik.handleBlur}
                                        // onChange={formik.handleChange}
                                        value={user?.firstName}
                                        name="firstname"
                                        id="firstname"
                                        disabled
                                        className="inputD"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicLastName">
                                    <Form.Label className='inputLabel'>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Last Name"
                                        // onBlur={formik.handleBlur}
                                        // onChange={formik.handleChange}
                                        value={user?.lastName}
                                        name="lastname"
                                        id="lastname"
                                        disabled
                                        className="inputD"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label className='inputLabel'>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user?.username}
                                        name="username"
                                        id="username"
                                        disabled
                                        className="inputD"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row xxl={12} xl={12} lg={12}>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="formBasicPhone" >
                                    <Form.Label className='inputLabel'>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user?.phone}
                                        name="phone"
                                        id="phone"
                                        disabled
                                        className="inputD"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label className='inputLabel'>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={user?.email}
                                        name="email"
                                        id="email"
                                        disabled
                                        className="inputD"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label className='inputLabel'>Password</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setPass(e.target.value)}
                                        value={pass}
                                        name="password"
                                        id="password"
                                        className="input"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ padding: '0px 16px' }}>
                            <Button
                                onClick={() => editPassword()}
                                className='editSubmitButton'
                            >
                                Edit
                            </Button>
                        </Row>
                    </div>
                </Col>
                {/* <Col xxl={5} xl={5} lg={5} className="ProfileImageContainer">
                    <img src={profile} style={{ width: '100%', height: '100%' }} />
                </Col> */}
            </Row>
        </Container>
    )
}

export default Profile