import React from 'react'
import './style.css'
import { Container } from 'react-bootstrap'
import moment from 'moment'
import logo from '../../assets/images/platinumPunters.png'

const Footer = () => {
     const Sports = [
        {
            link: 'Basketball',
        },
        {
            link: 'Football',
        },
        {
            link: 'Handball',
        },
        {
            link: 'Baseball',
        },
    ]
    
    return (
        <div className='footerWrapper'>
            {/* <Container className='footerBootstrapContainer'>
                <div className='footerContainer'>
                    <div style={{width:"100%"}}>
                        <div className='footerLinksContainer'>
                            <div className='footerTitles'>
                                <p>Sports</p>
                            </div>
                            <div className='footerBodyLink'>
                                {
                                    Sports.map(link => (
                                        <div className='footerLink'>
                                            <p>{link.link}</p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div style={{display:'flex' , alignItems:"center" , justifyContent:"space-evenly"}}>
                            <div className='footerEmailContainer'>
                                <div className='footerTitles'>
                                    <p>GET IN TOUCH</p>
                                </div>
                                <div className='footerBody'>
                                    <p>support@platinumPunters.com</p>
                                </div>
                            </div>
                            <div className='footerAdressContainer'>
                                <div className='footerTitles'>
                                    <p>ADRESS</p>
                                </div>
                                <div className='footerBody'>
                                    <p>12345 Elm Street, Apartment 6789, Unit 101112,<br /> Building D, Tower 13, Central District, Downtown Area,<br /> Cityville, Stateville, United States, 54321-9876</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container> */}
            <div className='copyrightContainer'>
                <p>Copyright {moment().format('YYYY')} Crypto Recovery – All rights reserved</p>
            </div>
        </div>
    )
}

export default Footer