import React, { useState } from 'react'
import './styles.css'
import { leagues } from '../usaLeagues/data'
import games from './games.json'
import moment from 'moment'
import { Carousel, Container } from 'react-bootstrap'

const Games = () => {
    const [gameLeagueSelected, setGameLeagueSelected] = useState('NHL')

    return (
        <div className='GamesWrapper'>
            <div>
                <div className='GamesHeaderContainer'>
                    {
                        leagues?.map((league) => {
                            return (
                                <div className={league?.leagueName === gameLeagueSelected ? 'GamesHeaderCardSelected' : 'GamesHeaderCard'} onClick={() => setGameLeagueSelected(league?.leagueName)}>
                                    {/* <img src={league?.icon} width={30} height={30} /> */}
                                    <p>{league?.leagueName}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='GamesContentContainer'>
                    {
                        games[gameLeagueSelected]?.games?.map((match, index) => (
                            <div key={index} className='gamesContainerOverlay'>
                                    <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                        <img src={match?.homeTeam?.logo} alt="Home Team Logo" style={{ width: '80px', height: 'auto' }} />
                                        <h5>{match?.homeTeam?.abbrev}</h5>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: "center", flexDirection: 'column', gap: "0px" }}>
                                        <p>
                                            {moment().add(match?.gameDate, "days").format("DD/MM")}
                                        </p>
                                        <div className='gamesScoreDate'>
                                            {
                                                (match?.homeTeam?.score && match?.awayTeam?.score) ?
                                                    <h5 style={{margin:0}}>{match?.homeTeam?.score} : {match?.awayTeam?.score}</h5>
                                                    :
                                                    <h5 style={{margin:0}}>{moment(match?.startTimeUTC).format('HH:mm a')}</h5>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: "center", flexDirection: "column" }}>
                                        <img src={match?.awayTeam?.logo} alt="Away Team Logo" style={{ width: '80px', height: 'auto' }} />
                                        <h5>{match?.awayTeam?.abbrev}</h5>
                                    </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default Games