import axios from 'axios';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getHighlights } from '../src/api';
import './App.css';
import Games from './components/games';
import Highlights from './components/highlights';
import IntroductionImage from './components/introductionImage';
import Navigation from './components/navigation';
import NbaDrafts from './components/nbaDrafts';
import NbaRankings from './components/nbaRanking';
import News from './components/news';
import NhlLeaders from './components/nhlLeaders';
import Reviews from './components/reviews';
import USALeagues from './components/usaLeagues';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import NhlTopClubs from './components/NhlTopClubs';
import PremierLeagueGoals from './components/PremierLeagueGoals';
import MlsPlayerStats from './components/MlsPlayerStats';


function App() {
  const [news, setNews] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("loading" , loading)

  useLayoutEffect(() => {
    // setLoading(true)
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
        setLoading(false)
      })
      .catch(error => console.log(error));

    getHighlights()?.then(response => {
      setLoading(false)
      setHighlights(response?.tvhighlights)
    })
      .catch(error => console.log(error));
    setLoading(false)
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Games />
      <NhlTopClubs />
      <Row>
        <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
          <News news={news} loading={loading} />
        </Col>
        <Col xxl={8} xl={8} lg={8} md={6} sm={12}>
          <NbaRankings />
        </Col>
      </Row>
        <Highlights highlights={highlights} loading={loading} />
        <PremierLeagueGoals />
        <Row>
          {/* <Col xxl={4} xl={4} lg={6} md={6} sm={12} style={{ padding: 16 }}>
            <NhlLeaders />
          </Col> */}
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: 16 }}>
            <NbaDrafts firstRound={true} />
          </Col>
          <MlsPlayerStats />
        </Row>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Reviews />
      </Row>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
