import React from 'react'
import './styles.css'
import nbaDrafts from '../../nbaDrafts.json'
import { cutText } from '../../helpers/getHelpers'
import { Accordion, Col, Row } from 'react-bootstrap'
import Title from '../title'

const NbaDrafts = () => {
    return (
        <div className='NbaDraftsWrapper'>
             <div style={{paddingLeft:'16px'}}>
                <Title title="NBA Drafts" />
            </div>
            <div className='NbaDraftsContentContainer'>
                {
                    nbaDrafts?.map((draft) => {
                        return (
                            <>
                                <div className='NbaDraftsContent'>
                                    <div style={{display:"flex" , alignItems:"center" , justifyContent:"center"}}>
                                        {
                                            draft?.Headshot ?
                                                <img src={`https://global.nba.com${draft?.Headshot}`} width={80} height={80} className='nbaHead'/>
                                                :
                                                <img src={`https://cdn.nba.com/logos/nba/${draft?.TeamID}/primary/L/logo.svg`} width={80} height={80} className='nbaLogo'/>
                                        }
                                    </div>
                                    <div className='NbaDraftsPickNoContainer'>
                                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                            <h6 className='nbaDraftsPickNo'>{draft?.PickNo}</h6>
                                            <p style={{ fontSize: '14px' }}><b>{draft?.Position}</b></p>
                                        </div>
                                        <p style={{margin:0 , textAlign:"center", fontWeight:"bolder"}}>{draft?.TeamFullName}</p>
                                        <div style={{display:"flex" , alignItems:"center" , justifyContent:"center" , flexDirection:"column"}}>
                                            <p style={{ fontSize: '14px',margin:0 }}>{draft?.PlayerFirstName}</p>
                                            <p style={{ fontSize: '14px' ,margin:0}}>{draft?.PlayerLastName}</p>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NbaDrafts