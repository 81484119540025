import React from 'react'
import Title from '../title'
import mlsPlayerStats from '../../mlsPlayerStats.json'
import './styles.css'

const MlsPlayerStats = () => {
    return (
        <div className='MlsPlayerStatsWrapper'>
            <Title title="MLS Players Stats" />
            <div style={{ display: "flex", flexWrap: 'wrap', gap: "24px", alignItems: "center", justifyContent: "space-between", marginTop: '32px', padding: "24px" }}>
                {
                    Object.keys(mlsPlayerStats)?.map((key) => {
                        return (
                            <div className='MlsLeagueData'>
                                <h3 style={{ marginBottom: "24px", textTransform: "capitalize", color: "black" }}>{key}</h3>
                                <div className='MlsLeagueDataContent'>
                                    {
                                        mlsPlayerStats[key]?.slice(0, 1)?.map((player) => {
                                            return (
                                                <div className='MlsLeagueDataContentFirst'>
                                                    <div style={{ display: "flex", height: "100%", width: "130px", border: '1px solid black', borderRadius: '0px', overflow: "hidden", background: 'black' }}>
                                                        <img src={`https://images.mlssoccer.com/image/private/w_175,h_175,c_pad/f_png/mls/${player?.image}`} width={130} />
                                                    </div>
                                                    <div style={{ padding: 16, display: "flex", flexDirection: "column" }}>
                                                        <div style={{ width: "100%" }}>
                                                            <h3>{player?.player?.first_name.charAt(0)}. {player?.player?.last_name}</h3>
                                                            <p style={{ marginBottom: "4px" }}>{player?.club?.name}</p>
                                                        </div>
                                                        <div style={{ textAlign: "center" }}>
                                                            <h1>
                                                                {key === "goals" && player?.regular_season_statistics?.goals}
                                                                {key === "assists" && player?.regular_season_statistics?.goal_assist}
                                                                {key === "key Passes" && player?.regular_season_statistics?.total_att_assist}
                                                                {key === "shots" && player?.regular_season_statistics?.total_scoring_att}
                                                            </h1>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    <div style={{ display: 'flex', flexWrap: "wrap" , padding:"8px"}}>
                                        {
                                            mlsPlayerStats[key]?.slice(1, 4)?.map((player) => {
                                                return (
                                                    <div style={{ width:"100%",marginTop: '8px', padding: "8px", borderRadius: '0px', display: "flex", justifyContent: "space-between" , color: "black" , background:"#909090" }}>
                                                        <div style={{ display: "flex", gap: '8px' }}>
                                                            <h5 style={{ margin: 0 }}>{player?.player?.first_name.charAt(0)}. {player?.player?.last_name}</h5>
                                                            <p style={{ margin: 0, opacity: 0.5 }}>{player?.club?.abbreviation}</p>
                                                        </div>
                                                        <h4 style={{ margin: 0 }}>
                                                            {key === "goals" && player?.regular_season_statistics?.goals}
                                                            {key === "assists" && player?.regular_season_statistics?.goal_assist}
                                                            {key === "key Passes" && player?.regular_season_statistics?.total_att_assist}
                                                            {key === "shots" && player?.regular_season_statistics?.total_scoring_att}
                                                        </h4>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MlsPlayerStats