import React from 'react'
import './styles.css'
import Title from '../title'
import premierleagueGoals from '../../premierLeagueGoals.json'

const PremierLeagueGoals = () => {
    return (
        <div style={{ padding: "16px" }}>
            <Title title="2023-2024 Premier League Goals" />
            <div style={{ display: "flex", gap: 16, overflowX: 'auto', paddingBottom: "8px" }}>
                {
                    premierleagueGoals?.content?.map((content) => {
                        return (
                            <div className='PremierleagueGoalsContent'>
                                <div style={{ padding: "16px" }}>
                                    {/* <h3 style={{ whiteSpace: "noWrap" }}>{content?.name?.display}</h3> */}
                                    <Title title={content?.rank +" "+content?.name?.display} />
                                    <div style={{ display: "flex", alignItems: "center", gap: '4px', marginTop: "16px" }}>
                                        <img src={`https://resources.premierleague.com/premierleague/badges/50/${content?.currentTeam?.altIds?.opta}@x2.png`} width={30} />
                                        <h6 style={{ margin: 0 }}>{content?.currentTeam?.name}</h6>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "flex-end", gap: '4px', marginTop: "8px" }}>
                                        <h3>Score: {content?.value}</h3>
                                    </div>
                                </div>
                                <div style={{ display: "flex"  , justifyContent:"center"}}>
                                    <div style={{ display: "flex", marginRight: "20px" }}>
                                        <img src={`https://resources.premierleague.com/premierleague/photos/players/110x140/${content?.altIds?.opta}.png`} height={190} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PremierLeagueGoals