import React from 'react'
import './styles.css'
import Title from '../title'
import nflTopClubs from '../../nhlTopClubs.json'

const NhlTopClubs = () => {
  return (
    <div style={{padding:'32px 16px'}}>
        <Title title="NHL Clubs" />
        <div style={{ display: "flex", gap: 16,flexDirection:'row', marginTop:"16px" , justifyContent:"space-between"}}>
                {
                    nflTopClubs?.clubs?.map((club) => {
                        return (
                            <div className='NflTopClubsContent'>
                                <div style={{display:"flex" , alignItems:"center" , gap:"16px"}}>
                                    <img src={club?.logo} width={70} height={70}/>
                                    <h4 style={{margin:0}}>{club.name}</h4>
                                </div>
                                <div style={{display:"flex" , gap:"8px"}}>
                                    <h6 style={{margin:"0px"}}>FMG: {club?.fmg} - </h6>
                                    <h6 style={{margin:"0"}}>FG %: {club?.fg}</h6>
                                </div>
                                <div style={{display:"flex", gap:'8px'}}>
                                    <h6 style={{margin:"0px"}}>XPM: {club?.xpm} - </h6>
                                    <h6 style={{margin:"0px"}}>XP Pct: {club?.xp_pct}</h6>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
    </div>
  )
}

export default NhlTopClubs