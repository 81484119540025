import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Yup from 'yup';
import { register } from '../../../api';
import ContactModal from '../../contactmodal';
import '../style.css';
import backgrnd from '../../../assets/images/signup.webp'
import Title from '../../title';


export default function SignUp({ SupportModalShow, setSupportModalShow, Close }) {
    const [startDate, setStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState()


    const [phoneValue, setPhoneValue] = useState()


    const validationSchema = Yup.object({
        firstname: Yup
            .string('first name')
            .required('First name is required'),
        lastname: Yup
            .string('last name')
            .required('Last name is required'),
        username: Yup
            .string('User name')
            .required('Username is required'),
        password: Yup
            .string('password')
            .required('Password is required'),
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            birthdate: startDate,

        },
        validationSchema: validationSchema,
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: startDate,

            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage(response.data.message)
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })
    return (
        <Container fluid style={{ padding: 0 , display:'flex' , alignItems:"center" , justifyContent:"center"}}>
            {
                SupportModalShow ?
                    <>
                        <ContactModal Close={Close} setSupportModalShow={setSupportModalShow} />
                    </>
                    :
                    <>
                        <Row className='rowCont' style={{ padding: 0, margin: 0, overflowY: 'hidden' }}>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                                {SupportModalShow ? null
                                    : errorMessage ?
                                        <>
                                            <div style={{ padding: 16, marginBottom: 16, marginTop: 16, borderRadius: 0 }}>
                                                <p style={{ color: errorMessage ? 'red' : 'green' , marginBottom:"8px"}}>{errorMessage}</p>
                                                <Button
                                                    onClick={() => setSupportModalShow(true)}
                                                    style={{
                                                        borderRadius: '100px',
                                                        backgroundColor: '#121212',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        border: 'none'
                                                    }}>Contact Us</Button>
                                            </div>
                                        </>
                                        : null
                                }
                                <Title title='Sign Up' />
                                <Form onSubmit={formik.handleSubmit} style={{ width: '100%', marginTop: '24px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '100%' }} className='mb-0'>
                                            {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>First Name</h5></Form.Label> */}
                                            <Form.Control
                                                className='textInput'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.firstname}
                                                type="text"
                                                name="firstname"
                                                placeholder="first name"
                                            />
                                        </div>
                                        <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                            {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Last Name</h5></Form.Label> */}
                                            <Form.Control
                                                className='textInput'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.lastname}
                                                type="text"
                                                name="lastname"
                                                placeholder="last name"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '50%' }} className='mb-0'>
                                            {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Email Adress</h5></Form.Label> */}
                                            <Form.Control
                                                className='textInput'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                type="email"
                                                name="email"
                                                placeholder="email"
                                            />
                                        </div>
                                        <div style={{ width: '50%', marginLeft: 8 }} className='mb-0'>
                                            <Form.Group className="mb-0" controlId="formBasicPhone" style={{ marginRight: 0 }}>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Phone Number</h5></Form.Label> */}
                                                <PhoneInput
                                                    style={{ height: 40 }}
                                                    className='phoneNumber'
                                                    value={phoneValue}
                                                    onChange={setPhoneValue}
                                                    name="phone"
                                                    placeholder="phone number"
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex' }}>

                                        <div style={{ width: '100%' }} className='mb-0'>
                                            {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Username</h5></Form.Label> */}
                                            <Form.Control
                                                className='textInput'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                                type="text"
                                                placeholder="username"
                                                name="username" />
                                        </div>
                                        <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                            {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Password</h5></Form.Label> */}
                                            <Form.Control
                                                className='textInput'
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                type="password"
                                                name="password"
                                                placeholder="password"
                                            />
                                        </div>
                                    </div>

                                    <div className='mb-4'>
                                        <Form.Group controlId="formBasicBirthDate">
                                            <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Birth date</h5></Form.Label>
                                            <DatePicker
                                                className='date'
                                                selected={startDate}
                                                name='birthdate'
                                                onChange={(date) => setStartDate(date)}
                                                value={startDate}
                                            />
                                        </Form.Group>
                                    </div>

                                    <Button
                                        type="submit"
                                        className='signupSubmit'
                                    >
                                        Sign Up
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </>
            }

        </Container >
    )
}
