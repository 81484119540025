import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { contactUs } from '../../api';
import './styles.css'
import Title from '../title';

export default function ContactModal({ Close, setSupportModalShow }) {

    const [errorMessage, setErrorMessage] = useState()

    const formik = useFormik({
        initialValues: {
            email: '',
            message: '',
        },
        onSubmit: () => {
            const email = formik.values.email
            const message = formik.values.message;
            const func = contactUs(email, message)
            func.then(async (response) => {
                if (response.success) {
                    window.location.reload()
                    Close(true)
                    setSupportModalShow(false)
                }
            })
        }
    })



    return (
            <Container style={{display:'flex', alignItems:'center', justifyContent:'center' , height:'100%'}}>
                <Row style={{width:'100%'}}>
                    <div style={{paddingLeft:"16px"}}>
                        <Title title="Contact Us" />
                    </div>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{padding: "32px" }}>
                        <p style={{ color: 'red' }}>
                            {errorMessage}
                        </p>
                        <Form onSubmit={formik.handleSubmit}>
                            <p className='contactUsText'>To: <span>Support@aipunters.com</span></p>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    className='textInput'
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    type="email"
                                    placeholder="From"
                                    name="email" />
                                <Form.Control
                                    // className='textInput'
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.message}
                                    as="textarea"
                                    rows={5}
                                    placeholder="Message"
                                    name="message"
                                    style={{ marginTop: 8  , borderRadius:"8px"}} />
                            </Form.Group>
                            <Button type="submit" className='contactButton'>
                                Contact Us
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
    )
}
