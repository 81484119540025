import React, { useState } from 'react'
import './styles.css'
import rankings from '../../nbaRanking.json'
import { sortArrayAsc } from '../../helpers/getHelpers'
import Title from '../title'

const NbaRankings = () => {
    const [nbaState , setNbaState] = useState('Eastern')

    const states =[
        {
            name:'Eastern',
        },
        {
            name:'Western'
        }
    ]

    return (
        <div className='nbaRankingWrapper'>
           <Title title="NBA Rankings" />
            <div className='nbaRankingContent'>
                {
                    rankings?.standingGroups.map((ranking) => {
                        return (
                            <div className='nbaRankingContainer'>
                                <h4>{ranking?.conference}</h4>
                                <div className='nbaRankingGamesContainer'>
                                    {
                                        sortArrayAsc(ranking?.teams).map((team) => {
                                            return (
                                                <div className='nbaRankingGames'>
                                                    <div style={{display:"flex", alignItems:"center" , gap:'12px'}}>
                                                        <div className='nbaRankingGamesRank'>
                                                            <p style={{margin:0}}>{team?.standings?.confRank}</p>
                                                        </div>
                                                        <div className='nbaRankingGamesImageContainer'>
                                                            <img src={`https://cdn.nba.com/logos/nba/${team?.profile?.id}/primary/L/logo.svg`} width={40} height={40} />
                                                        </div>
                                                        <div className='nbaRankingGamesNameContainer'>
                                                            <p style={{margin:0}}>{team?.profile?.cityEn + " " + team?.profile?.nameEn}</p>
                                                        </div>
                                                    </div>
                                                    <div className='nbaRankingGamesWinLossContainer'>
                                                        <p style={{margin:0}}>W/L: <b>{team?.standings?.wins}</b>/{team?.standings?.losses}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesLast10Container'>
                                                        <p style={{margin:0}}>Last 10: {team?.standings?.last10}</p>
                                                    </div>
                                                    <div>
                                                        <p style={{margin:0}}>Streak: {team?.standings?.winStreak}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NbaRankings