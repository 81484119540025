import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { getTransactions } from '../../../../api';
import './styles.css';
import Title from '../../../title';

function Transactions() {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [transactions, setTransactions] = useState([]);

    useEffect(
        () => {
            getTransactions(id).then(async res => {
                // const transactionResult = [];
                // res.data.forEach(element => {
                //     if (element.status === 0) {
                //         transactionResult.push(element)
                //     }
                // });

                setTransactions(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Container style={{display:'flex', alignItems:'center', justifyContent:'center' , height:'100%'}}>
            <Row style={{width:"100%"}}>
              <div>
                <Title title="Transactions" />
              </div>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='transactionsWrapper' style={{ marginTop: "32px" }}>
                        <div className='transactionsContainer'>
                            <Table responsive="sm">
                                <thead className='transactionTableHead'>
                                    <tr>
                                        <th><b>From</b></th>
                                        <th><b>To</b></th>
                                        <th><b>Amount</b></th>
                                        <th><b>Currency</b></th>
                                        {/* <th><b>Date</b></th> */}
                                        <th><b>Status</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions?.map(item => (
                                            <>
                                                <tr key={item._id}>
                                                    <td align='center'><p>{cookie.get('username')}</p></td>

                                                    <td align='center'><p>{item.receiver}</p></td>

                                                    <td align='center'><p>{item.amount}</p></td>
                                                    <td align='center'><p>{item.sender.currency}</p></td>
                                                    {/* <td align='center'><p>{moment(item.createAt).format('L')}</p></td> */}

                                                    <td align='center'>
                                                        {
                                                            item.status === 0 ?
                                                                <>
                                                                    <Badge bg="warning" text="dark">
                                                                        Pending
                                                                    </Badge>
                                                                </>
                                                                :
                                                                <>
                                                                    <Badge bg="success">Success</Badge>
                                                                </>
                                                        }
                                                    </td>
                                                </tr>

                                            </>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                {/* <Col xxl={4} xl={4} lg={4} className='transactionImageContainer'>
                    <img src={transaction} style={{ width: '100%', height: '100%' }} />
                </Col> */}
            </Row>
        </Container>
    )
}

export default Transactions