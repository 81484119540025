import React from 'react'
import './styles.css'

const Title = ({ title }) => {
  return (
    <>
      <h2 className='title'>{title}</h2>
    </>
  )
}

export default Title