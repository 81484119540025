import React from 'react';
import avatar1 from '../../assets/images/avatar1.jpg';
import avatar10 from '../../assets/images/avatar10.jpg';
import avatar11 from '../../assets/images/avatar11.jpg';
import avatar12 from '../../assets/images/avatar12.jpg';
import avatar2 from '../../assets/images/avatar2.jpg';
import avatar3 from '../../assets/images/avatar3.jpg';
import avatar4 from '../../assets/images/avatar4.jpg';
import avatar5 from '../../assets/images/avatar5.jpg';
import avatar6 from '../../assets/images/avatar6.jpg';
import avatar7 from '../../assets/images/avatar7.jpg';
import avatar8 from '../../assets/images/avatar8.jpg';
import avatar9 from '../../assets/images/avatar9.jpg';
import Title from '../title';
import Rating from './rating';
import './styles.css';

const Reviews = () => {
    const reviews = [
        {
            id: 1,
            name: "John Smith",
            description: "BetKing helped me win big with their expert betting tips and advice. Highly recommended!",
            rating: "5",
            avatar: avatar1,
            size: "default"
        },
        {
            id: 2,
            name: "Sarah Johnson",
            description: "I'm extremely grateful to BetKing for providing me with the best betting strategies. Great service!",
            rating: "5",
            avatar: avatar2,
            size: "default"
        },
        {
            id: 3,
            name: "Emily Roberts",
            description: "BetKing has been a game-changer for me. Thanks to their service, I now bet with confidence.",
            rating: "5",
            avatar: avatar3,
            size: "tall"
        },
        {
            id: 4,
            name: "Mark Davis",
            description: "BetKing's team was responsive and knowledgeable, providing a clear plan of action.",
            rating: "5",
            avatar: avatar4,
            size: "wide"
        },
        {
            id: 5,
            name: "Jennifer Wilson",
            description: "I had a great experience with BetKing. They guided me to consistent wins.",
            rating: "5",
            avatar: avatar5,
            size: "default"
        },
        {
            id: 6,
            name: "Daniel Thompson",
            description: "BetKing kept me informed and updated every step of the way. Highly professional.",
            rating: "5",
            avatar: avatar6,
            size: "tall"
        },
        {
            id: 7,
            name: "Jessica Adams",
            description: "I'm extremely satisfied with BetKing's service. They helped me place winning bets.",
            rating: "5",
            avatar: avatar7,
            size: "default"
        },
        {
            id: 8,
            name: "Michael Martin",
            description: "BetKing is the real deal. They helped me win big with excellent customer support.",
            rating: "5",
            avatar: avatar8,
            size: "default"
        },
        {
            id: 9,
            name: "Sophie Brown",
            description: "BetKing's efficient and thorough approach helped me win. Highly recommended.",
            rating: "5",
            avatar: avatar9,
            size: "wide"
        },
        {
            id: 10,
            name: "William Thompson",
            description: "BetKing exceeded my expectations, helping me win when I thought it was impossible.",
            rating: "5",
            avatar: avatar10,
            size: "default"
        },
        {
            id: 11,
            name: "Olivia Martinez",
            description: "BetKing's knowledge and resources helped me win big. Highly recommended.",
            rating: "5",
            avatar: avatar11,
            size: "tall"
        },
        {
            id: 12,
            name: "Robert Johnson",
            description: "BetKing is a trusted partner for sports betting with top-notch service and expertise.",
            rating: "5",
            avatar: avatar12,
            size: "default"
        },
        {
            id: 13,
            name: "Emily Wilson",
            description: "I'm impressed by PlatinumPunters's professionalism and dedication to helping individuals win.",
            rating: "5",
            avatar: avatar1,
            size: "default"
        },
        {
            id: 14,
            name: "Andrew Davis",
            description: "BetKing provided invaluable guidance and insights to enhance my betting strategies.",
            rating: "5",
            avatar: avatar2,
            size: "wide"
        },
        {
            id: 15,
            name: "Sophia Adams",
            description: "I highly recommend BetKing for their expertise and efficient betting process. Great team!",
            rating: "5",
            avatar: avatar3,
            size: "default"
        },
        {
            id: 16,
            name: "Daniel Thompson",
            description: "BetKing's exceptional service and commitment to helping individuals win exceeded my expectations.",
            rating: "5",
            avatar: avatar4,
            size: "wide"
        },
        {
            id: 17,
            name: "Michael Wilson",
            description: "BetKing is a reliable partner for sports betting. Highly recommend their services.",
            rating: "5",
            avatar: avatar6,
            size: "default"
        },
        {
            id: 18,
            name: "Emily Brown",
            description: "I'm thankful to BetKing for their professionalism and dedication in helping me place winning bets.",
            rating: "5",
            avatar: avatar5,
            size: "default"
        },
        {
            id: 19,
            name: "Jacob Adams",
            description: "BetKing's expertise and effectiveness in helping me win my bets have been outstanding.",
            rating: "5",
            avatar: avatar8,
            size: "wide"
        },
        {
            id: 20,
            name: "Sophia Wilson",
            description: "BetKing provided excellent service and helped me win big. Highly recommended!",
            rating: "5",
            avatar: avatar7,
            size: "default"
        },
        {
            id: 21,
            name: "Benjamin Thompson",
            description: "BetKing's vast experience and deep understanding of the sports betting landscape set them apart.",
            rating: "5",
            avatar: avatar10,
            size: "wide"
        },
        {
            id: 22,
            name: "Olivia Martin",
            description: "I highly recommend BetKing for their exceptional service and commitment to helping bettors win.",
            rating: "5",
            avatar: avatar9,
            size: "default"
        },
        {
            id: 23,
            name: "David Thompson",
            description: "BetKing provided me with the peace of mind I needed by successfully helping me win my bets.",
            rating: "5",
            avatar: avatar12,
            size: "default"
        },
        {
            id: 24,
            name: "Ella Johnson",
            description: "I'm impressed by PlatinumPunters's professionalism and dedication to helping individuals win.",
            rating: "5",
            avatar: avatar11,
            size: "default"
        },
        {
            id: 25,
            name: "Matthew Davis",
            description: "BetKing is the best in the business. They helped me win my bets with exceptional service.",
            rating: "5",
            avatar: avatar2,
            size: "default"
        },
        {
            id: 26,
            name: "Sophie Thompson",
            description: "BetKing's personal attention and care made the experience much more manageable.",
            rating: "5",
            avatar: avatar1,
            size: "tall"
        },
        {
            id: 27,
            name: "Adam Wilson",
            description: "BetKing provided me with excellent service and helped me win big. Highly recommended!",
            rating: "5",
            avatar: avatar4,
            size: "default"
        },
        {
            id: 28,
            name: "Emma Johnson",
            description: "I'm extremely satisfied with PlatinumPunters's professionalism and expertise.",
            rating: "5",
            avatar: avatar3,
            size: "default"
        },
        {
            id: 29,
            name: "James Davis",
            description: "Winning with BetKing was both a financial and emotional victory. Their support was invaluable.",
            rating: "5",
            avatar: avatar6,
            size: "wide"
        }
    ];

    return (
        <div className='testimonialWrapper'>
            <div style={{ paddingLeft: '16px' }}>
                <Title title="Customers Reviews" />
            </div>
            <div className='testimonialContainer'>
                {
                    reviews?.map((review, index) => {
                        return (
                            <div key={index} className='testimonialContent'>
                                <img src={review?.avatar} width={70} height={70} style={{ borderRadius: '100px' }} />
                                <p className='testimonialContentName'>{review?.name}</p>
                                <Rating rating={review?.rating}/>
                                <p className='testimonialContentDescription'>{review?.description}</p>
                            </div>
                        );
                    })
                }
            </div>
        </div>

    )
}

export default Reviews